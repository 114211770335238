import React, { useState, useEffect } from 'react'
import { Typography, Table, Avatar, Button, Modal, Select, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllParticipants,
  updatePrivilege,
  addParticipants,
  removeParticipants
} from '../../reducers/participantsReducer'
import { useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import { DateTime } from 'luxon'
import { FlexSectionHeader } from '../style'
import { ADMIN } from '../../constants/userRoles'
import { PlusOutlined } from '@ant-design/icons'
import { getAuthHeader } from '../../services/config'
import './index.css'

const { Title, Text } = Typography
const { Column } = Table
const { Option } = Select

const CourseParticipants = () => {
  const dispatch = useDispatch()
  const { courseId } = useParams()

  const participants = useSelector((state) => state.courseParticipants.data)
  const loading = useSelector((state) => state.courseParticipants.loading)
  const user = useSelector((state) => state.auth.user)
  const courses = useSelector((state) => state.courses.data)

  const course = courses.find((course) => course.id === courseId)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [selectedParticipant, setSelectedParticipant] = useState(null)

  const [clickedRowKey, setClickedRowKey] = useState(null)
  const [clickedCell, setClickedCell] = useState(null)

  useEffect(() => {
    dispatch(getAllParticipants(courseId))
  }, [courseId, dispatch])

  // const handleSearch = async (query) => {
  //   // Simulate API call to search users (replace with actual API)
  //   const results = await fetch(`/api/search/users?q=${query}`).then((res) =>
  //     res.json()
  //   )
  //   setSearchResults(results)
  // }
  const handleSearch = async (query) => {
    // setSearchQuery(query)

    // Fetch the authorization headers
    const headers = getAuthHeader()

    try {
      // Make the API call with authentication headers
      const response = await fetch(`/backend/users/search?q=${query}`, {
        method: 'GET',
        headers: {
          ...headers, // Spread the auth headers
          'Content-Type': 'application/json' // If needed
        }
      })

      if (!response.ok) {
        throw new Error('Error fetching search results')
      }

      const results = await response.json()
      setSearchResults(results)
    } catch (error) {
      console.error('Failed to fetch search results:', error)
    }
  }

  // const handleAddParticipant = async () => {
  //   if (selectedParticipant) {
  //     try {
  //       // await dispatch(addParticipants(courseId, [selectedParticipant]))
  //       message.success('Participant added successfully')
  //       setIsModalVisible(false)
  //       setSelectedParticipant(null)
  //       dispatch(getAllParticipants(courseId)) // Refresh participants list
  //     } catch (error) {
  //       message.error('Failed to add participant')
  //     }
  //   } else {
  //     message.warning('Please select a participant')
  //   }
  // }
  const handleRemoveParticipant = async (userId) => {
    try {
      // Call API to remove the participant from the course

      await dispatch(removeParticipants(courseId, userId))
      message.success('Participant removed successfully')

      // Refresh the participants list after successful removal
      window.location.reload()
      dispatch(getAllParticipants(courseId))
    } catch (error) {
      message.error('Failed to remove participant')
      console.error('Error:', error)
    }
  }
  const handleAddParticipant = async () => {
    if (selectedParticipant && selectedParticipant._id) {
      try {
        console.log(`The participant added is: ${selectedParticipant.name}`) // Log the participant's name

        // Uncomment when actual API logic is added
        await dispatch(addParticipants(courseId, [selectedParticipant]))

        message.success('Participant added successfully')
        setIsModalVisible(false)
        setSelectedParticipant(null)

        dispatch(getAllParticipants(courseId)) // Refresh participants list
      } catch (error) {
        message.error('Failed to add participant')
      }
    } else {
      message.warning('Please select a participant')
    }
  }

  const handleClick = (record, columnKey) => {
    console.log('Clicked on:', record.user.name)
    setClickedCell({ rowKey: record.id, columnKey })
    // You can add any other action here.
  }

  if (loading) return <Spinner size="large" />

  return (
    <>
      <FlexSectionHeader>
        <Title level={3}>All participants</Title>
        {user.role === ADMIN && (
          <>
            <Button
              type="dashed"
              shape="round"
              icon={<PlusOutlined />}
              onClick={() => setIsModalVisible(true)}
            >
              Add participant
            </Button>
            <Modal
              title="Add Participant"
              visible={isModalVisible}
              onOk={handleAddParticipant}
              onCancel={() => setIsModalVisible(false)}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Search for users"
                onSearch={handleSearch}
                value={selectedParticipant ? selectedParticipant._id : null} // Use selectedParticipant.id
                onChange={(value) => {
                  const selected = searchResults.find(
                    (userParticipant) => userParticipant._id === value
                  )
                  const selected1 = searchResults.find((userParticipant) => {
                    console.log('user.id:', userParticipant._id)
                    console.log('value', value)
                    return userParticipant._id === value
                  })
                  console.log('selected:', selected)
                  if (selected) {
                    setSelectedParticipant(selected) // Set the selected participant properly
                  }
                }}
                filterOption={false}
              >
                {searchResults.map((userParticipant, index) => (
                  <Option key={userParticipant._id} value={userParticipant._id}>
                    {userParticipant.name} ({userParticipant.email})
                  </Option>
                ))}
              </Select>

              {/* <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Search for users"
                onSearch={handleSearch}
                value={selectedParticipant}
                onChange={(value) => setSelectedParticipant(value)}
                filterOption={false} // Disables local filtering, relies on API results
              >
                {searchResults.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.name} ({user.email})
                  </Option>
                ))}
              </Select> */}
            </Modal>
          </>
        )}
      </FlexSectionHeader>

      <Table
        pagination={false}
        style={{ marginTop: '24px' }}
        rowKey={(participant) => participant.id}
        dataSource={participants}
      >
        <Column
          title="Photo"
          dataIndex={['user', 'photo']}
          render={(img) => <Avatar src={img} />}
        />
        <Column
          title="Name"
          dataIndex={['user', 'name']}
          onCell={(record) => ({
            onClick: () => {
              console.log('record:', record)
              return handleClick(record, 'name')
            },
            className:
              clickedCell?.rowKey === record.id &&
              clickedCell?.columnKey === 'name'
                ? 'ant-table-cell-clicked'
                : ''
          })}
        />
        <Column title="Email" dataIndex={['user', 'email']} />
        <Column
          title="Enrollment Date"
          dataIndex="createdAt"
          render={(time) => {
            return DateTime.fromISO(time).toLocaleString(DateTime.DATETIME_MED)
          }}
        />
        <Column
          title="Privilege"
          dataIndex="enrolledAs"
          render={(privilege) => <Text>{privilege}</Text>}
        />
        <Column
          title="Action"
          render={(text, record, index) => {
            if (!user) {
              console.log('user is null')
            }
            console.log('record:', record)

            if (user._id === record.user._id) {
              return 'Current user'
            }
            if (course?.createdBy?._id === record.user._id) {
              return 'Course Owner'
            }
            return (
              <>
                {record.enrolledAs === 'student' && (
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => {
                      dispatch(
                        updatePrivilege(courseId, record.id, 'instructor')
                      )
                    }}
                  >
                    Make instructor
                  </Button>
                )}
                {record.enrolledAs === 'instructor' && (
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => {
                      dispatch(updatePrivilege(courseId, record.id, 'student'))
                    }}
                  >
                    Make student
                  </Button>
                )}
                {record.enrolledAs === 'admin' && '-'}
              </>
            )
          }}
        />
        <Column
          title="Remove"
          render={(text, record) => {
            return (
              <Button
                type="link"
                danger
                onClick={() => handleRemoveParticipant(record.user._id)}
              >
                Remove
              </Button>
            )
          }}
        />
      </Table>
    </>
  )
}

export default CourseParticipants
