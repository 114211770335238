import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import axios from 'axios'
import useCoursePrivilege from '../../hooks/useCourseprivilege'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Spin, message, Select, Modal, Button } from 'antd'

const { Option } = Select

const CalendarView = () => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [view, setView] = useState('timeGridWeek') // Default view
  const [selectedEvent, setSelectedEvent] = useState(null) // Store the selected event
  const { enrolled, privilege } = useCoursePrivilege()
  const user = useSelector((state) => state.auth.user)
  const { courseId } = useParams()

  const fetchSchedule = async () => {
    const startDate = new Date().toISOString().split('T')[0]
    const endDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0]

    try {
      setLoading(true)
      const response = await axios.get('/backend/meetings/meetings-calendar', {
        params: {
          userId: user._id,
          role: user.role,
          startDate,
          endDate,
          courseId
        }
      })

      const formattedEvents = response.data.data.map((event) => {
        console.log('Mapping Event:', event) // Add this to debug individual items
        return {
          title: event.title,
          start: event.start,
          end: event.end,
          url: event.meetLink
        }
      })
      setEvents(
        response.data.data.map((event) => ({
          title: event.title || 'Meeting',
          start: event.start,
          end: event.end,
          extendedProps: {
            ...event
          }
        }))
      )
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error)
      message.error('Failed to load calendar events')
    }
  }

  useEffect(() => {
    fetchSchedule()
  }, [user._id, user.role, courseId])

  const handleViewChange = (value) => {
    setView(value)
  }

  const handleEventClick = (info) => {
    const { title, extendedProps } = info.event
    // Open the modal with the event's details
    setSelectedEvent({ title, extendedProps })
  }

  const handleModalClose = () => {
    setSelectedEvent(null) // Close the modal
  }

  return (
    <div style={{ padding: '20px', background: '#fff', borderRadius: '8px' }}>
      <h2 style={{ marginBottom: '20px' }}>
        Calendar for {user.role} Schedule
      </h2>
      {/* <div
        style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}
      >
        <Select
          defaultValue={view}
          onChange={handleViewChange}
          style={{ width: 200, marginRight: '15px' }}
        >
          <Option value="dayGridMonth">Monthly View</Option>
          <Option value="timeGridWeek">Weekly View</Option>
          <Option value="timeGridDay">Daily View</Option>
        </Select>
        {loading && <Spin size="small" />}
      </div> */}

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={view}
        events={events}
        height="80vh"
        headerToolbar={{
          start: 'prev,next today',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        eventClick={handleEventClick}
      />

      {/* Modal for event details */}
      <Modal
        visible={!!selectedEvent}
        title={selectedEvent?.title}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>
        ]}
      >
        <div>
          <h4>Event Details:</h4>
          <p>
            <strong>Start Time:</strong> {selectedEvent?.extendedProps?.start}
          </p>
          <p>
            <strong>End Time:</strong> {selectedEvent?.extendedProps?.end}
          </p>
          <p>
            <strong>Meeting Link:</strong>{' '}
            <a
              href={selectedEvent?.extendedProps?.meetLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Meeting
            </a>
          </p>
          {/* <p>
            <strong>Additional Information:</strong>
          </p>
          <pre>{JSON.stringify(selectedEvent?.extendedProps, null, 2)}</pre> */}
        </div>
      </Modal>
    </div>
  )
}

export default CalendarView
